import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "text-center mt-8" },
        [
          _c(
            VBtn,
            {
              attrs: {
                color: "primary",
                rounded: "",
                fab: "",
                top: "",
                right: "",
                absolute: "",
              },
              on: {
                click: function ($event) {
                  return _vm.add_file_dialog()
                },
              },
            },
            [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
            1
          ),
        ],
        1
      ),
      !_vm.companiesLoading
        ? _c(VSimpleTable, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("name")))]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("document_type"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(" " + _vm._s(_vm.$tc("company", 1)) + " "),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(" " + _vm._s(_vm.$t("download")) + " "),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(" " + _vm._s(_vm.$t("remove")) + " "),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.files, function (file, i) {
                return _c("tr", { key: i }, [
                  _c("td", [_vm._v(_vm._s(file.FileName))]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          file.UserFileType
                            ? _vm.$t(_vm.documentTypeText(file.UserFileType))
                            : "-"
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.get_file_company(file.CompanyId))),
                  ]),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1 btnDownloadFile",
                          attrs: {
                            icon: "",
                            download: file.FileName,
                            id: file.FileName,
                            name: file.FileName,
                            color: "primary",
                            "min-width": "0",
                            small: "",
                            loading: _vm.fileDownloading == file.Path,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.download_contract(file, file.FileName)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-download"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1",
                          attrs: {
                            disabled: !file.CanBeDeleted,
                            color: "red",
                            "min-width": "0",
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openDeleteDialog(file)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-trash-can"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("td", { staticClass: "text-center" }),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm.dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                VCard,
                { staticClass: "dxa_modal" },
                [
                  _c(VCardTitle, [
                    _c("h4", { staticClass: "dxa_modal_title h4" }, [
                      _vm._v(_vm._s(_vm.$t("new_file"))),
                    ]),
                  ]),
                  _c(
                    VCardText,
                    [
                      _c(
                        VForm,
                        {
                          ref: "form",
                          staticClass: "mt-5",
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.check_form()
                            },
                          },
                        },
                        [
                          _c(VSelect, {
                            attrs: {
                              items: _vm.index_options,
                              label: _vm.$t("index"),
                              rules: [_vm.required],
                              outlined: "",
                            },
                            model: {
                              value: _vm.selected_index,
                              callback: function ($$v) {
                                _vm.selected_index = $$v
                              },
                              expression: "selected_index",
                            },
                          }),
                          _c(VSelect, {
                            attrs: {
                              items: _vm.companies,
                              "item-text": "Name",
                              "item-value": "CompanyId",
                              label: _vm.$tc("company", 1),
                              outlined: "",
                            },
                            model: {
                              value: _vm.company_id,
                              callback: function ($$v) {
                                _vm.company_id = $$v
                              },
                              expression: "company_id",
                            },
                          }),
                          _c(VSelect, {
                            attrs: {
                              items: _vm.documentOptions,
                              "item-text": _vm.translateFilesOptions,
                              "item-value": "value",
                              label: _vm.$t("document"),
                              rules: [_vm.required],
                              outlined: "",
                            },
                            model: {
                              value: _vm.selectedDocumentType,
                              callback: function ($$v) {
                                _vm.selectedDocumentType = $$v
                              },
                              expression: "selectedDocumentType",
                            },
                          }),
                          !_vm.edit
                            ? _c(VFileInput, {
                                attrs: {
                                  prefix: "",
                                  label: "File input",
                                  rules: [_vm.required],
                                },
                                on: { change: _vm.handleSelectFile },
                              })
                            : _vm._e(),
                          _c(
                            VCardActions,
                            { staticClass: "pl-0 dxa_modal_actions" },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "dxa_modal_btnSuccess",
                                  attrs: {
                                    color: "primary",
                                    "min-width": "100",
                                    loading: _vm.loading,
                                    type: "submit",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("save")))]
                              ),
                              _c(
                                VBtn,
                                {
                                  staticClass: "dxa_modal_btnError",
                                  attrs: { color: "red", "min-width": "100" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialog = false
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("cancel")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.delete_dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.delete_dialog,
                callback: function ($$v) {
                  _vm.delete_dialog = $$v
                },
                expression: "delete_dialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: { obj: _vm.delete_obj, name: _vm.delete_obj.FileName },
                on: {
                  close: function ($event) {
                    _vm.delete_dialog = false
                  },
                  delete: _vm.deleteFile,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }