import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { id: "add-User", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3",
              attrs: {
                color: "primary",
                icon: "mdi-account",
                title: _vm.page_title(),
              },
            },
            [
              _c(
                VTabs,
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.edit,
                      expression: "edit",
                    },
                  ],
                  on: { change: _vm.reset_alert },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(VTab, [_vm._v(_vm._s(_vm.$t("basic")))]),
                  _vm.userType === _vm.userTypeEnum.Investor
                    ? _c(VTab, [_vm._v(_vm._s(_vm.$t("files")))])
                    : _vm._e(),
                  _vm.userType === _vm.userTypeEnum.Investor
                    ? _c(VTab, [_vm._v(_vm._s(_vm.$t("managed_portfolio")))])
                    : _vm._e(),
                  _vm.userType === _vm.userTypeEnum.Partner
                    ? _c(VTab, { attrs: { disabled: !_vm.partnerB2bId } }, [
                        _vm._v(_vm._s(_vm.$tc("partner", 1))),
                      ])
                    : _vm._e(),
                  _vm.userType === _vm.userTypeEnum.Investor
                    ? _c(VTab, [_vm._v(" PREFERÊNCIAS DE CARTEIRA ")])
                    : _vm._e(),
                  _vm.userType === _vm.userTypeEnum.Investor
                    ? _c(VTab, [_vm._v(_vm._s(_vm.$t("bank_acc")))])
                    : _vm._e(),
                  _vm.userType === _vm.userTypeEnum.Investor
                    ? _c(VTab, [
                        _vm._v(_vm._s(_vm.$tc("background_check", 2))),
                      ])
                    : _vm._e(),
                  _vm.userType === _vm.userTypeEnum.Banker
                    ? _c(VTab, [_vm._v(_vm._s(_vm.$tc("banker", 1)))])
                    : _vm._e(),
                  _vm.userType === _vm.userTypeEnum.Investor
                    ? _c(VTab, [_vm._v(_vm._s(_vm.$tc("extract", 2)))])
                    : _vm._e(),
                ],
                1
              ),
              _vm.tab == _vm.tabsEnum.BASIC
                ? _c("ManageUserBasic", {
                    attrs: { user_prop: _vm.user },
                    on: { alert: _vm.show_alert, saved: _vm.user_saved },
                  })
                : _vm._e(),
              _vm.edit && _vm.userType == _vm.userTypeEnum.Investor
                ? _c("ManageUserFiles", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab == _vm.tabsEnum.FILES,
                        expression: "tab == tabsEnum.FILES",
                      },
                    ],
                    attrs: { user_prop: _vm.user },
                    on: { reset: _vm.update_files },
                  })
                : _vm._e(),
              _vm.edit && _vm.userType == _vm.userTypeEnum.Investor
                ? _c("UserPortfolioInfo", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab == _vm.tabsEnum.MANAGED_PORTFOLIO,
                        expression: "tab == tabsEnum.MANAGED_PORTFOLIO",
                      },
                    ],
                    attrs: { User: _vm.userPortfolio },
                  })
                : _vm._e(),
              _vm.edit && _vm.userType == _vm.userTypeEnum.Investor
                ? _c("HighlightedCompanies", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab == _vm.tabsEnum.PREFERENCES,
                        expression: "tab == tabsEnum.PREFERENCES",
                      },
                    ],
                    attrs: { User: _vm.user },
                    on: { saved: _vm.handleSavedHighlightedCompanies },
                  })
                : _vm._e(),
              _vm.tab == _vm.tabsEnum.FILES &&
              _vm.userType == _vm.userTypeEnum.Partner
                ? _c("ManageUserPartner", {
                    attrs: { user_prop: _vm.partnerB2bInfo },
                    on: { alert: _vm.show_alert, saved: _vm.user_saved },
                  })
                : _vm._e(),
              _vm.tab == _vm.tabsEnum.FILES &&
              _vm.userType == _vm.userTypeEnum.Banker
                ? _c("ManageUserBanker", {
                    attrs: { user_prop: _vm.user },
                    on: { alert: _vm.show_alert, saved: _vm.user_saved },
                  })
                : _vm._e(),
              _vm.edit && _vm.userType == _vm.userTypeEnum.Investor
                ? _c("ManageBankAccounts", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab == _vm.tabsEnum.BANK_ACCOUNTS,
                        expression: "tab == tabsEnum.BANK_ACCOUNTS",
                      },
                    ],
                    attrs: {
                      User: _vm.user,
                      BankAccounts: _vm.user.BankAccounts,
                    },
                  })
                : _vm._e(),
              _vm.edit && _vm.userType == _vm.userTypeEnum.Investor
                ? _c("ManageBackgroundCheck", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab == _vm.tabsEnum.BACKGROUND_CHECK,
                        expression: "tab == tabsEnum.BACKGROUND_CHECK",
                      },
                    ],
                    attrs: {
                      User: _vm.user,
                      BankAccounts: _vm.user.BankAccounts,
                    },
                  })
                : _vm._e(),
              _vm.edit && _vm.userType == _vm.userTypeEnum.Investor
                ? _c("ExtractTableComponent", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab == _vm.tabsEnum.EXTRACT,
                        expression: "tab == tabsEnum.EXTRACT",
                      },
                    ],
                    attrs: { userId: _vm.user_id },
                  })
                : _vm._e(),
              _vm.alert_msg != null
                ? _c(
                    VAlert,
                    {
                      staticClass: "ma-2",
                      attrs: { type: _vm.success ? "success" : "error" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(_vm.alert_msg)) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }